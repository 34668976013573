import http from "./httpService";

const apiEndpoint = "/billing-profiles";

export async function getAllProfiles() {
  let url = `${apiEndpoint}/`;
  try {
    return await http.get(url);
  } catch (error) {
    return { error };
  }
}

export async function createProfiles(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.post(url, data);
  } catch (error) {
    return { error };
  }
}
export async function updateProfile(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.put(url, data);
  } catch (error) {
    return { error };
  }
}
export async function deleteProfile(id) {
  let url = `${apiEndpoint}/${id}`;
  try {
    return await http.delete(url);
  } catch (error) {
    return { error };
  }
}
