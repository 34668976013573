// import React from "react";

// const BillingManagement = () => {
//   return (
//     <div>
//       <h3>Billing Management</h3>

//     </div>
//   );
// };

// export default BillingManagement;
import CurrencyInput from "react-currency-input-field";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import uniqId from "uniqid";

import NoDataBox from "./common/noDataBox";
import InputFieldLayoutRow from "./common/inputFieldLayoutRow";
import {
  createProfiles,
  deleteProfile,
  getAllProfiles,
  updateProfile,
} from "../services/billingProfileService";
import { toast } from "react-toastify";
import { formatAsCurrency } from "../functions/main";

function BillingProfileManagement(props) {
  const [billingProfile, setBillingProfile] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [newBillingProfile, setNewBillingProfile] = useState({
    ID: "",
    Program: "",
    BasePay: "",
    SupplementalPay: "",
    Proration: "", // Changed initial state to empty string
  });
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const res = await getAllProfiles();
      setBillingProfile(res.data);
    }
    fetchData();
  }, []);

  // Handle Edit Program
  const handleEdit = (id) => {
    const profileToEdit = billingProfile.find((profile) => profile.ID === id);
    setNewBillingProfile(profileToEdit);
    setShowForm(true);
    setIsEditing(true);
    setEditId(id);
  };

  // Handle Delete Program
  const handleDelete = async (id) => {
    const res = await deleteProfile(id);
    if (res) fetchData();
    toast.success("Billing Profile deleted successfully");
  };

  // Handle Form Change
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "Proration") {
      // Set Proration to 'Y' if checked, else ''
      setNewBillingProfile((prevState) => ({
        ...prevState,
        [name]: checked ? "Y" : "",
      }));
    } else {
      setNewBillingProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  async function fetchData() {
    const res = await getAllProfiles();
    setBillingProfile(res.data);
  }

  // Handle Form Submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setShowForm(false);

    if (isEditing) {
      // Update existing infraction
      const res = await updateProfile(newBillingProfile);
      if (res.data.Program)
        toast.success("Billing Profile updated successfully");
      if (res.data) fetchData();
    } else {
      const res = await createProfiles(newBillingProfile);
      console.log(res.data);
      if (res.data.Program) toast.success("Billing Profile added successfully");
      if (res.data) fetchData();
    }

    setIsEditing(false);
    setEditId(null);
    setNewBillingProfile({
      ID: "",
      Program: "",
      BasePay: "",
      SupplementalPay: "",
      Proration: "", // Reset to empty string
    });
  };

  // Handle Cancel Form
  const handleFormCancel = () => {
    setShowForm(false);
    setIsEditing(false);
    setEditId(null);
    setNewBillingProfile({
      ID: "",
      Program: "",
      BasePay: "",
      SupplementalPay: "",
      Proration: "", // Reset to empty string
    });
  };

  return (
    <div className="residentView-sectionBox mx-0">
      <div className="w-full flex flex-row justify-between mb-4">
        <div>
          <h2 className="text-3xl font-semibold text-teal-600 mb-6">
            {!showForm
              ? "Billing Profile Management"
              : isEditing
              ? "Update Billing Profile"
              : "Add Billing Profile"}
          </h2>
        </div>
        <div>
          {!showForm && (
            <button
              onClick={() => {
                setShowForm(true);
                setIsEditing(false);
                setNewBillingProfile({
                  ID: "",
                  Program: "",
                  BasePay: "",
                  SupplementalPay: "",
                  Proration: "",
                });
              }}
              className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 text-lg no-underline"
            >
              Create New Billing Profile
            </button>
          )}
        </div>
      </div>

      {showForm ? (
        <form
          onSubmit={handleFormSubmit}
          className="mb-8 max-w-lg mx-auto bg-white shadow-lg rounded-lg p-8"
        >
          <div className="mb-6">
            <label className="block text-gray-800 text-lg font-semibold mb-3">
              Program:
            </label>
            <input
              type="text"
              name="Program"
              value={newBillingProfile.Program}
              onChange={handleFormChange}
              className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 text-lg leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-800 text-lg font-semibold mb-3">
              Base Pay:
            </label>
            <CurrencyInput
              id="BasePay"
              name="BasePay"
              placeholder=""
              prefix="$"
              value={newBillingProfile.BasePay}
              className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 text-lg leading-tight focus:outline-none focus:shadow-outline"
              decimalsLimit={2}
              onValueChange={(value, name, values) =>
                setNewBillingProfile({ ...newBillingProfile, BasePay: value })
              }
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-800 text-lg font-semibold mb-3">
              Supplemental Pay:
            </label>
            <CurrencyInput
              id="SupplementalPay"
              name="SupplementalPay"
              placeholder=""
              prefix="$"
              value={newBillingProfile.SupplementalPay}
              className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 text-lg leading-tight focus:outline-none focus:shadow-outline"
              decimalsLimit={2}
              onValueChange={(value, name, values) =>
                setNewBillingProfile({
                  ...newBillingProfile,
                  SupplementalPay: value,
                })
              }
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-800 text-lg font-semibold mb-3">
              Proration:
            </label>
            <input
              type="checkbox"
              name="Proration"
              checked={newBillingProfile.Proration === "Y"}
              onChange={handleFormChange}
              className="mr-3 leading-tight scale-150 ms-2"
            />
            <span className="text-gray-700 text-lg">Yes</span>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-teal-500 text-white font-bold px-6 py-3 rounded-lg hover:bg-teal-600 text-lg"
            >
              {isEditing ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              onClick={handleFormCancel}
              className="bg-gray-500 text-white font-bold px-6 py-3 rounded-lg hover:bg-gray-600 text-lg"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 shadow-md rounded text-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-700 uppercase text-lg leading-normal">
                {/* <th className="py-3 px-6 text-left">ID</th> */}
                <th className="py-3 px-6 text-left">Program</th>
                <th className="py-3 px-6 text-left">Base Pay</th>
                <th className="py-3 px-6 text-left">Supplemental Pay</th>
                <th className="py-3 px-6 text-center">Proration</th>
                <th className="py-3 px-6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-lg font-normal">
              {billingProfile?.length > 0 ? (
                billingProfile
                  // .sort((a, b) => a.Program.localeCompare(b.Program))
                  .map((profile) => (
                    <tr
                      key={profile.ID}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      {/* <td className="py-3 px-6 text-left whitespace-nowrap">
                      {profile.ID}
                    </td> */}
                      <td className="py-3 px-6 text-left">{profile.Program}</td>
                      <td className="py-3 px-6 text-left">
                        {formatAsCurrency(profile.BasePay)}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {formatAsCurrency(profile.SupplementalPay)}
                      </td>
                      <td className="py-3 px-6 text-center">
                        {profile.Proration ? "Yes" : "No"}
                      </td>
                      <td className="py-3 px-6 text-center">
                        <button
                          onClick={() => handleEdit(profile.ID)}
                          className="bg-teal-500 text-white px-3 py-2 rounded hover:bg-teal-600 mr-2 text-lg"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(profile.ID)}
                          className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600 text-lg"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={5} className="py-3 px-6 text-center">
                    No Billing Profile available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default BillingProfileManagement;
